<template>
  <div class="footer">
    <div class="container">
      <h5>Link Instansi Terkait</h5>
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="img">
          <a
            href="https://www.lapor.go.id/instansi/pemerintah-kabupaten-bandung"
          >
            <img src="@/assets/icon/lapor.png" width="100%" />
          </a>
        </div>
        <div class="img">
          <a href="https://www.pu.go.id/">
            <img src="@/assets/icon/pu.png" width="100%" />
          </a>
        </div>
        <div class="img">
          <a href="https://www.bandungkab.go.id/"
            ><img src="@/assets/icon/kab-bandung.png" width="100%"
          /></a>
        </div>
        <div class="img">
          <a href="https://jabarprov.go.id/">
            <img src="@/assets/icon/jawa-barat.png" width="100%"
          /></a>
        </div>
      </div>
    </div>

    <div class="footer-detail">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15839.002914408613!2d107.54533631857255!3d-7.038555022566805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68ec3898815889%3A0x8488df50cdd399c1!2sDinas%20Pekerjaan%20Umum%20Kabupaten%20Bandung!5e0!3m2!1sid!2sid!4v1627741953485!5m2!1sid!2sid"
              width="100%"
              height="250px"
              style="border: 0"
              allowfullscreen="true"
              loading="lazy"
            ></iframe>
          </div>
          <div class="col-lg-6">
            <div class="container-fluid">
              <h4>Dinas Pekerjaan Umum Dan Tata Ruang Kabupaten Bandung</h4>
              <p><strong>Kontak :</strong></p>
              <p>(022) 5892773 - 5892580</p>

              <p><strong>Alamat :</strong></p>
              <p>
                Jalan Raya Banjaran Soreang Km 3, Ciherang No.2 Tanjungsari,
                Cangkuang, Bandung, Jawa Barat
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      markers: [
        {
          position: {
            lat: -7.025253,
            lng: 107.51976,
          },
        },
      ],
    };
  },
  methods: {
    resizeIframe(obj) {
      obj.style.height =
        obj.contentWindow.document.documentElement.scrollHeight + "px";
    },
  },
};
</script>

<style>
.footer {
  background-color: #036163;
  margin-top: 30px;
  padding-top: 30px;
  color: #ffff;
  border-radius: 0px 150px 0px 0px;
}

.img {
  padding: 20px;
}

.footer-detail {
  background-color: #217475;
  padding: 30px;
}
</style> 