<template>
  <div class="mobile">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      label-next=""
      label-prev=""
      background="#ababab"
      img-width="1024"
      img-height="350"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with image only -->
      <b-carousel-slide
        img-src="@/assets/images/slide_1.png"
      ></b-carousel-slide>

      <b-carousel-slide
        img-src="@/assets/images/slide_2.png"
      ></b-carousel-slide>

      <b-carousel-slide
        img-src="@/assets/images/slide_3.png"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mobile {
    margin-top: 63px;
  }
}
</style>