<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center">
        <span> <b-icon-person></b-icon-person> {{ name }} </span>
        <span>
          <b-icon-calendar></b-icon-calendar> {{ date.slice(0, 10) }}
        </span>
      </div>
    </div>
    <img class="card-img-top" :src="image" alt="Card image cap" />
    <div class="status" :style="generateStatusColor(status)">
      Pengaduan PJU Kab Bandung | {{ generateStatus(status) }}
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <!-- <div>
          <b-icon-hand-thumbs-up class="reaction"></b-icon-hand-thumbs-up>
          <b-icon-chat-dots class="reaction"></b-icon-chat-dots>
        </div> -->
        <div class="no-complaint">{{ no_complaint }}</div>
      </div>
      <p class="card-text">
        {{ complaint.slice(0, 35) + "....." }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "Edvin Megantara",
    },
    date: {
      type: String,
      default: "04-05-2021 18:39:42",
    },
    status: {
      default: 0,
    },
    no_complaint: {
      default: "PJ-KAB-0100001",
    },
    complaint: {
      default:
        "Lampu di daerah ini sering mati dan mengeluarkan percikan api kemungkinan ada konsleting pada lampu tersebut.",
    },
    image: {
      default: "@/assets/images/photo-complaint.png",
    },
  },

  methods: {
    // enum status complaint
    // 0 => PENGADUAN DITERIMA,
    // 1 => PENGADUAN DIVERIFIKASI,
    // 2 => PENGADUAN DALAM PROSES,
    // 3 => PENGADUAN SELESAI DIPROSES
    // 4 => PENGADUAN DITOLAK
    generateStatus(data) {
      switch (data) {
        case 0:
          return "PENGADUAN DITERIMA";
        case 1:
          return "PENGADUAN DIVERIFIKASI";
        case 2:
          return "DALAM PROSES";
        case 3:
          return "SELESAI DIPROSES";
        case 4:
          return "DITOLAK";
      }
    },
    generateStatusColor(item) {
      switch (item) {
        case 0:
          return "background-color: #FBC400 !important";
        case 1:
          return "background-color: #FBC400 !important";
        case 2:
          return "background-color: #00ACEC !important";
        case 3:
          return "background-color: #1A7273 !important";
        case 4:
          return "background-color: #DC143C !important";
      }
    },
  },
  mounted() {
    this.generateStatusColor();
  },
};
</script>

<style scoped>
span {
  font-size: x-small;
}

.status {
  font-size: 9px;
  font-weight: bold;
  /* background-color: #dc3545; */
  padding: 5px;
  color: #ffff;
}
.card {
  width: 18rem;
}
.reaction {
  margin: 5px;
  color: #1c8e42;
}
.card-img-top {
  height: 200px !important;
  object-fit: cover !important;
}
.no-complaint {
  color: #036163;
  font-size: smaller;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card {
    width: 100%;
  }
}
</style>