/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */

import axios from 'axios';

export default {

  namespaced: true,

  state: {
    authMe : {},
    token: '' || localStorage.getItem('token'),

    user: {} || JSON.parse(localStorage.getItem('user')),
  },
  mutations: {
    setAuth(state, data) {
      state.authMe = data;
    },
    loginSuccess(state, { token, user }) {
      state.token = token;
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },

    loginFailed(state) {
      state.token = '';
      state.user = {};
    },


  },
  actions: {
    getAuthMe({ commit }) {
      return new Promise((resolve, reject) => {
        const _url = `/auth/me`;

        axios.get(_url).then((resp) => {
          commit('setAuth', resp.data);
          // console.log(resp.data.data);
          resolve(resp.data);
        }).catch((err) => { console.log(err); reject(err); });
      });
    },
    login({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.post('/auth/login', data).then((resp) => {
          const token = `bearer ${resp.data.access_token}`;
          const user = resp.data.data;
          localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = token;
          commit('loginSuccess', { token, user });
          resolve(resp);
        }).catch((err) => {
        //   console.log(err);
          commit('loginFailed');
          reject(err);
        });
      }));
    },
    register(_, 
      _data
    ) {
      return new Promise((resolve, reject) => {
        const _url = '/auth/register';
        axios.post(_url, _data).then((resp) => {
          // console.log(resp.data.data);
          resolve(resp.data.data);
        }).catch((err) => { console.log(err); reject(err); });
      });
    },
    logout({ commit }) {
      localStorage.setItem('token', '');
      commit('loginFailed');
    },

  },
  getters: {
    isLoggedIn: state => state.token,
    getUserData: state => state.user,
  },


};
