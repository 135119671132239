<template>
  <div>
    <modal
      :clickToClose="false"
      height="auto"
      width="450px"
      :adaptive="true"
      name="auth-modal"
      style="z-index: 9999"
    >
      <div class="p-4">
        <h4 class="text-center text-main text-uppercase"><b> Masuk </b></h4>
        <hr />
        <p class="text-center text-main">
          Login terlebih dahulu sebelum melakukan pengaduan
        </p>
        <div class="row mt-3">
          <div class="col-lg-12 mx-auto">
            <label for="">Email : </label>
            <input
              type="email"
              v-model="form.email"
              class="form-control mb-2"
              placeholder="contoh@email.com"
            />

            <label for="">Kata Sandi :</label>
            <input
              v-model="form.password"
              type="password"
              class="form-control mb-2"
              placeholder="masukkan kata sandi"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              @click="login"
              class="btn btn-block bg-main"
              style="width: 100%"
            >
              <b> MASUK </b>
            </button>
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-center text-main">Anda belum memiliki akun?</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              class="btn btn-block bg-warning text-main"
              style="width: 100%"
              @click="$router.replace('/register')"
            >
              <b> DAFTAR SEKARANG </b>
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal
      :clickToClose="false"
      class="bg-transparent"
      height="auto"
      width="150px"
      name="check-token"
    >
      <div class="p-4">
        <div class="d-flex flex-column justify-content-center text-center">
          <img height="100px" src="@/assets/loading.svg" alt="" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },

  methods: {
    login() {
      this.$modal.hide("auth-modal");
      this.$modal.show("check-token");
      setTimeout(() => {
        this.$store
          .dispatch("auth/login", this.form)
          .then((resp) => {
            console.log(resp);
            this.$toast.success("Login berhasil !!");
            this.$modal.hide("check-token");
          })
          .catch((e) => {
            console.log(e);
            this.$toast.error("Login gagal " + e);
            this.showLogin();
          });
      }, 2000);
    },
    showLogin() {
      this.$modal.hide("check-token");
      this.$modal.show("auth-modal");
    },
    checkToken() {
      const token = localStorage.getItem("token");
      if (token) {
        this.$store
          .dispatch("auth/getAuthMe")
          .then(() => {
            this.$modal.hide("check-token");
          })
          .catch(() => {
            setTimeout(() => {
              this.showLogin();
            }, 2000);
          });
      } else {
        setTimeout(() => {
          this.showLogin();
        }, 2000);
      }
    },
  },

  mounted() {
    this.checkToken();
  },
};
</script>