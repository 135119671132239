import Vue from 'vue'
import Vuex from 'vuex'
import complaint from './complaint';
import auth from './auth';


Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    complaint,
    auth
  },
})