<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Shared/Navbar.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer
  },
};
</script>

<style>
</style>

