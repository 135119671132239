<template>
  <div class="main-content">
    <b-card
      class="container"
      style="border-radius: 20px !important"
      title="PENGADUAN PJU TERBARU"
    >
      <div class="d-flex justify-content-evenly" v-if="!isLoading">
        <div class="row">
          <div
            class="col-lg-4 mb-2 mx-auto"
            v-for="index in complaint.length"
            :key="index"
            @click="toDetail(complaint[index - 1])"
          >
            <ComplaintCard
              :no_complaint="complaint[index - 1].no_complaint"
              :complaint="complaint[index - 1].content"
              :status="
                complaint[index - 1].complaint_status[
                  complaint[index - 1].complaint_status.length - 1
                ].status
              "
              :name="complaint[index - 1].user.full_name"
              :date="complaint[index - 1].created_at"
              :image="complaint[index - 1].complaint_documents[0].url_file"
            />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg mx-auto text-center">
          <button
            class="btn bg-white text-main"
            style="width: 100%; border-radius: 20px"
            @click="$router.push('/complaints')"
          >
            <b>Lihat Lainnya</b>
          </button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ComplaintCard from "../Shared/ComplaintCard.vue";

export default {
  components: {
    ComplaintCard,
  },
  data() {
    return {
      complaint: [],
      isLoading: true,
    };
  },
  methods: {
    toDetail($item) {
      console.log($item);
      this.$router.push({ name: "detail", params: { data: $item } });
    },
  },
  mounted() {
    this.$store
      .dispatch("complaint/getComplaint", { _page: 1, _row: 6 })
      .then((resp) => {
        this.complaint = resp.data;
        console.log(resp.data);
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
.main-content {
  background-color: #ffffff;
  padding-top: 30px;
}

.container {
  background-color: #036163;
}

.card-title {
  text-align: center;
  color: #ffffff;
}
</style>