import axios from 'axios';
// import { resolve } from 'core-js/fn/promise';

export default {
    namespaced: true,

    state: {
        complaint: {},
    },
    mutations: {
        setComplaint(state, data)
        {
            state.complaint = data;
        }
    },
    actions: {
        // page=1&row=20&sortby=created_at&sorttype=desc&status=[1, 2, 3, 4, 5,6 , 7]
        addComplaint(_, 
            _data
          ) {
            return new Promise((resolve, reject) => {
              const _url = '/complaint';
              axios.post(_url, _data).then((resp) => {
                // console.log(resp.data.data);
                resolve(resp.data.data);
              }).catch((err) => { console.log(err); reject(err); });
            });
          },
        getComplaint({commit}, {_page, _row}){



            const  _params = {
                page:_page,
                row: _row ? _row : 20,
                sortby: 'created_at',
                sorttype: 'desc',
                status: '[1,2,3,4,5,6,7]'
            }

            return new Promise((resolve) => {
                axios.get('/web_complaint', {params: _params}).then(resp => {
                    resolve(resp.data.data);
                    commit('setComplaint', resp.data.data)
                }).catch(e => {
                    console.log(e);
                });
            })
        },
        getComplaintById(_, {_id}){



            return new Promise((resolve) => {
                axios.get('/complaint/' + _id ).then(resp => {
                    resolve(resp.data.data);
                    
                }).catch(e => {
                    console.log(e);
                });
            })
        }
    },

    getters: {

    }
}