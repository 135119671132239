<template>
  <div class="bg">
    <div class="bg-image">
      <div class="row" style="padding-top: 150px"></div>
      <div class="row">
        <div class="col-lg-8 col-sm-12 mb-3 mx-auto">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="text-uppercase text-main text-center">
                <b> Isi pendaftaran </b>
              </h5>
              <hr />
              <div class="row mb-3">
                <div class="col-lg-3 mx-auto" v-if="!preview" @click="getDocs">
                  <div
                    style="
                      height: 150px;
                      border-color: #036163 !important ;
                      border-style: dashed !important;
                    "
                    class="
                      border
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <span
                      class="material-icons"
                      style="font-size: 40px; color: #036163"
                      >add_photo_alternate</span
                    >
                    <p class="text-main mt-1">
                      Upload Foto <br />
                      <span style="font-size: 9px">(maks: 2MB) </span>
                    </p>
                    <input
                      type="file"
                      accept=".png,.jpg"
                      id="docs"
                      @change="setDocs"
                      hidden
                    />
                  </div>
                  <div class="progress mt-3">
                    <div
                      class="
                        progress-bar progress-bar-striped progress-bar-animated
                      "
                      role="progressbar"
                      :style="
                        'width: ' +
                        this.progress +
                        '%; background-color: #0d6769;'
                      "
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <p class="m-0 text-center" style="font-weight: bold">
                        {{ this.progress.toFixed(0) + " %" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="preview"
                  class="
                    col-lg-3
                    mx-auto
                    d-flex
                    text-center
                    flex-column
                    justify-content-center
                  "
                >
                  <vue-load-image>
                    <img
                      :src="preview"
                      style="
                        height: 150px;
                        width: 100%;
                        object-fit: cover !important;
                      "
                      slot="image"
                      alt=""
                    />
                    <img
                      slot="preloader"
                      height="50px"
                      width="50px"
                      src="@/assets/loading.svg"
                    />
                    <div slot="error">error message</div>
                  </vue-load-image>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="">Nama lengkap:</label>
                  <input
                    type="text"
                    class="form-control mb-2"
                    placeholder="Masukkan nama lengkap"
                    v-model="form.full_name"
                  />

                  <label for="">No HP:</label>
                  <input
                    type="text"
                    v-model="form.phone_number"
                    class="form-control mb-2"
                    placeholder="08xx xxxx xxxx"
                  />
                  <label for="">Kata sandi:</label>
                  <input
                    type="password"
                    v-model="form.password"
                    class="form-control mb-2"
                    placeholder="******"
                  />
                </div>
                <div class="col">
                  <label for="">Alamat lengkap:</label>
                  <input
                    type="text"
                    v-model="form.address"
                    class="form-control mb-2"
                    placeholder="Masukkan alamat lengkap"
                  />

                  <label for="">Email:</label>
                  <input
                    type="email"
                    class="form-control mb-2"
                    v-model="form.email"
                    placeholder="contoh@email.com"
                  />
                  <label for="">Konfirmasi kata sandi:</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    @keyup="getConfirmPassword()"
                    v-model="confirmPassword"
                    placeholder="Konfirmasi password baru"
                    :class="this.class.isPasswordValid"
                  />
                  <small
                    v-if="
                      this.class.isPasswordValid == 'form-control is-invalid'
                    "
                    class="text-danger mt-1"
                    >Pastikan Password sesuai</small
                  >
                  <small
                    v-if="this.class.isPasswordValid == 'form-control'"
                    class="mt-1"
                    >Pastikan password sesuai</small
                  >
                  <small
                    v-if="this.class.isPasswordValid == 'form-control is-valid'"
                    class="mt-1"
                    >Password sesuai</small
                  >
                </div>
              </div>
            </div>
            <button @click="register" class="btn m-3 btn-block bg-main">
              <b>DAFTAR</b>
            </button>
          </div>
        </div>
      </div>
      <modal
        :clickToClose="false"
        class="bg-transparent"
        height="auto"
        width="150px"
        name="loading"
      >
        <div class="p-4">
          <div class="d-flex flex-column justify-content-center text-center">
            <img height="100px" src="@/assets/loading.svg" alt="" />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import VueLoadImage from "vue-load-image";
// import AuthModal from "../components/Shared/AuthModal.vue";

export default {
  components: {
    VueLoadImage,
    // AuthModal,
  },
  data() {
    return {
      progress: 0,
      preview: null,
      form: {},

      confirmPassword: null,
      class: {
        isPasswordValid: "form-control ",
      },
    };
  },

  methods: {
    getConfirmPassword() {
      if (this.form.password != this.confirmPassword) {
        this.class.isPasswordValid = "form-control is-invalid";
      } else {
        this.class.isPasswordValid = "form-control is-valid";
      }
    },
    register() {
      if (
        confirm("Pastikan semua data benar dan tidak ada kolom yang kosong !!")
      ) {
        this.form.type = 0;
        this.form.profile_picture = this.preview;
        this.$modal.show("loading");

        setTimeout(() => {
          this.$store
            .dispatch("auth/register", this.form)
            .then(() => {
              this.$modal.hide("loading");
              this.$toast.success(
                "Pendaftaran berhasil !! \nSilahkan login menggunakan email dan password yang sudah didaftarkan"
              );
              this.$router.replace("/report");
            })
            .catch((e) => {
              this.$modal.hide("loading");
              this.$toast.error("Pendaftaran gagal !! \n" + e);
            });
        }, 1500);
      }
    },

    setDocs(event) {
      this.uploadFile(event.target.files[0]);
    },
    getDocs() {
      document.getElementById("docs").click();
    },
    uploadFile(file) {
      var storageRef = firebase
        .storage()
        .ref("docPengaduan/" + file.name)
        .put(file);
      // storageRef.put(this.file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          alert("Permintaan Gagal Diproses!!\n\n" + error.message);
        },
        () => {
          this.progress = 100;
          storageRef.snapshot.ref.getDownloadURL().then((resp) => {
            this.preview = resp;
            // console.log();
            // alert("Selamat! File Berhasil Diupload!!");
            this.progress = 0;
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.bg {
  background: rgb(103, 160, 161);
  background: linear-gradient(
    270deg,
    rgba(103, 160, 161, 1) 0%,
    rgba(3, 97, 99, 1) 100%
  );
}
.bg-image {
  background-image: url("../assets/bg.png");
  background-size: cover;
}
</style>