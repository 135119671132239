<template>
  <div>
    <Banner />
    <br />
    <h3 class="text-center text-uppercase text-main"><b> Pengaduan </b></h3>

    <center v-if="isLoading" class="mt-3">
      <vue-loading />
    </center>
    <div class="row m-2 px-3" v-if="!isLoading">
      <div
        class="col-lg-4 p-2 mb-2"
        v-for="item in complaint.data"
        :key="item.no_complaint"
        @click="toDetail(item)"
      >
        <ComplaintCard
          class="shadow"
          style="width: 100%; border-top: #036163 2px solid"
          :no_complaint="item.no_complaint"
          :complaint="item.content"
          :status="
            item.complaint_status[item.complaint_status.length - 1].status
          "
          :name="item.user.full_name"
          :date="item.created_at"
          :image="item.complaint_documents[0].url_file"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mx-auto d-flex justify-content-center">
        <nav>
          <ul class="pagination">
            <li class="page-item">
              <button
                class="page-link bg-main"
                @click="pageUp"
                aria-label="Next"
                :disabled="pages < length ? false : true"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <template v-for="count in length">
              <li
                v-if="pages < count + 5 && pages > count - 5"
                :key="count.id"
                :class="['page-item', 'click']"
                @click="goto(count)"
              >
                <a
                  :class="[
                    'page-link',
                    pages == count ? 'active-page' : 'bg-green',
                  ]"
                  >{{ count }}</a
                >
              </li>
            </template>
            <li class="page-item">
              <button
                class="page-link bg-main"
                @click="pageUp"
                aria-label="Next"
                :disabled="pages < length ? false : true"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Home/Banner.vue";
import ComplaintCard from "@/components/Shared/ComplaintCard.vue";

export default {
  components: {
    Banner,
    ComplaintCard,
  },
  data() {
    return {
      complaint: {},
      isLoading: true,
      length: null,
      pages: 1,
    };
  },

  methods: {
    toDetail($item) {
      // console.log($item);
      this.$router.push({ name: "detail", params: { data: $item } });
    },
    getComplaint() {
      this.$store
        .dispatch("complaint/getComplaint", { _page: this.pages })
        .then((resp) => {
          this.complaint = resp;
          this.length = resp.last_page;
          console.log(length);
          this.isLoading = false;
        });
    },
    pageUp() {
      this.isLoading = true;
      this.pages = this.pages + 1;
      this.getComplaint();
    },
    pageDown() {
      this.isLoading = true;
      this.pages = this.pages - 1;
      this.getComplaint();
    },
    goto(count) {
      this.isLoading = true;
      this.pages = count;
      this.getComplaint();
    },
  },

  mounted() {
    this.getComplaint();
  },
};
</script>

<style scoped>
.active-page {
  background-color: darkcyan !important;
  color: white;
}
body {
  width: 100% !important;
}
</style>