
<template>
  <div>
    <template>
      <div class="row" v-show="zoomImage">
        <div class="col zoom-body">
          <span @click="hideZoomImage()" class="x-close material-icons">
            close
          </span>
          <img class="zoom-image" :src="bigImage" />
        </div>
      </div>
    </template>
    <div fluid class="main-content-container px-4">
      <!-- Page Header -->

      <div no-gutters class="row page-header pt-4 mt-5">
        <div class="col-lg-4 text-center mx-auto text-sm-left">
          <h3 class="page-title text-main"><b> Detail Pengaduan </b></h3>
        </div>
      </div>
      <div class="container mt-2">
        <div class="row mb-2 mb-lg-0 px-2">
          <div class="col-lg-5">
            <div class="card shadow">
              <div class="card-header" style="background-color: #0d6769">
                <span class="m-auto text"
                  >ID PENGADUAN : {{ details.no_complaint }}</span
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 text-content">
                    <table class="table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            <span class="material-icons mt-1 text-main"
                              >event</span
                            >
                          </td>
                          <td>
                            {{
                              $moment(details.created_at).format(
                                "DD MMMM YYYY - HH:mm:ss"
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="material-icons mt-1 text-main"
                              >account_circle</span
                            >
                          </td>
                          <td>
                            {{ details.user.full_name }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="material-icons mt-1 text-main"
                              >phone_iphone</span
                            >
                          </td>
                          <td>
                            {{ details.user.phone_number }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <!-- <div class="row"> -->
                    <table class="table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td>Kecamatan</td>
                          <td>:</td>
                          <td>
                            {{ details.location ? details.location : "-" }}
                          </td>
                        </tr>
                        <tr>
                          <td>Ruas Jalan</td>
                          <td>:</td>
                          <td>{{ details.address }}</td>
                        </tr>
                        <tr>
                          <td>Koordinat</td>
                          <td>:</td>
                          <td>
                            {{ details.latitude }}, {{ details.longitude }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 mt-3 px-4 mt-lg-0">
            <div class="row">
              <div class="card shadow">
                <div class="card-header p-1 m-0 mb-2">
                  <div class="row">
                    <span
                      v-bind:class="{ active: menu === 'isi' }"
                      @click="menu = 'isi'"
                      class="col-4 col-lg-4 menu-laporan"
                      >ISI LAPORAN</span
                    >
                    <span
                      v-bind:class="{ active: menu === 'foto' }"
                      @click="menu = 'foto'"
                      class="col-8 col-lg-8 menu-laporan"
                      >FOTO/LAMPIRAN LAPORAN</span
                    >
                  </div>
                </div>
                <div v-show="menu === 'isi'" class="card-body">
                  <p style="text-align: left !important">
                    {{ details.content }}
                  </p>
                </div>
                <div v-show="menu === 'foto'" class="card-body">
                  <div class="row">
                    <div class="col">
                      <splide :options="options">
                        <splide-slide
                          v-for="image in details.complaint_documents"
                          :key="image.id"
                        >
                          <img
                            @click="showZoomImage(image.url_file)"
                            class="img-content"
                            :src="image.url_file"
                          />
                        </splide-slide>
                      </splide>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-2 mt-2">
          <div class="col-lg-5">
            <div class="card shadow map">
              <div class="card-body">
                <div style="height: 40px; overflow: auto" class="mb-3">
                  <!-- <p>First marker is placed at {{ withPopup.lat }}, {{ withPopup.lng }}</p>
                    <p>Center is at {{ currentCenter }} and the zoom is: {{ currentZoom }}</p> -->
                  <button
                    :class="['btn', showMap ? 'btn-danger' : 'btn-success', ,]"
                    style="width: 100%"
                    @click="showMap = !showMap"
                  >
                    <span v-if="showMap">Tutup Map</span>
                    <span v-else-if="!showMap">Buka Map</span>
                  </button>
                </div>
                <div style="height: 100%">
                  <l-map
                    v-if="showMap"
                    :zoom="zoom"
                    :center="center"
                    :options="mapOptions"
                    style="height: 85%"
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                  >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-marker :lat-lng="withTooltip">
                      <l-tooltip
                        :options="{ permanent: true, interactive: true }"
                      >
                        <div @click="innerClick">
                          Lokasi
                          <p v-show="showParagraph">
                            {{ details.address }}
                          </p>
                        </div>
                      </l-tooltip>
                    </l-marker>
                  </l-map>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 mt-3 px-4 mt-lg-0">
            <div class="row">
              <div class="card shadow">
                <div class="card-header p-1 m-0 mb-2">
                  <div class="row">
                    <span
                      v-bind:class="{ active: tab === 'status' }"
                      @click="tab = 'status'"
                      class="col-6 col-lg-6 menu-laporan"
                      >STATUS PENGADUAN</span
                    >
                    <span
                      v-bind:class="{ active: tab === 'tindakan' }"
                      @click="tab = 'tindakan'"
                      class="col-6 col-lg-6 menu-laporan"
                      >TINDAKAN PENGADUAN</span
                    >
                  </div>
                </div>
                <div v-show="tab === 'status'" class="card-body px-0">
                  <table class="table table-striped">
                    <thead class="bg-main text-center">
                      <th>Status</th>
                      <th>Tanggal</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in details.complaint_status"
                        :key="item.no_complaint"
                      >
                        <td>{{ generateStatus(item.status) }}</td>
                        <td>
                          {{
                            $moment(item.created_at).format(
                              "DD MMMM YYYY - HH:mm:ss"
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-show="tab === 'tindakan'" class="card-body px-0">
                  <table class="table table-striped">
                    <thead class="bg-main text-center">
                      <th class="row-tindakan">Tindakan</th>
                      <th>Tanggal</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in details.complaint_actions"
                        :key="item.no_complaint"
                      >
                        <td class="row-tindakan">{{ item.action }}</td>
                        <td>
                          {{ $moment(item.created_at).format("DD MMMM YYYY") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 px-2">
          <div class="col-lg-12 text-center">
            <div class="card">
              <div class="card-header p-0">
                <p class="bg-main"><b> DOKUMENTASI PERBAIKAN </b></p>
              </div>
              <div class="card-body">
                <splide :options="options">
                  <splide-slide
                    v-for="image in details.complaint_documents"
                    :key="image.id"
                  >
                    <img
                      v-if="image.type_user == 2"
                      @click="showZoomImage(image.url_file)"
                      class="img-content"
                      :src="image.url_file"
                    />
                    <p v-if="image.type_user != 2">Belum ada perbaikan</p>
                  </splide-slide>
                </splide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "leaflet/dist/leaflet.css";

// // or
// import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// // or
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "detail",
  components: {
    Splide,
    SplideSlide,
    // modal,
    LMap,
    LTileLayer,
    LMarker,

    LTooltip,
  },
  data() {
    return {
      isLoading: false,
      options: {
        rewind: true,
        width: "100%",
        height: 250,
        // perPage: 1,
        gap: "2rem",
      },
      details: [],
      isApproval: true,
      isModalVisible: false,
      zoomImage: false,
      bigImage: "",
      zoom: 13,
      center: latLng(
        this.$route.params.data.latitude,
        this.$route.params.data.longitude
      ),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withTooltip: latLng(
        this.$route.params.data.latitude,
        this.$route.params.data.longitude
      ),
      currentZoom: 11.5,
      currentCenter: latLng(
        this.$route.params.data.latitude,
        this.$route.params.data.longitude
      ),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      menu: "isi",
      tab: "status",
      notes: "",
      userId: null,
      userStatus: null,
    };
  },
  methods: {
    generateStatus(data) {
      switch (data) {
        case 0:
          return "PENGADUAN DITERIMA";
        case 1:
          return "PENGADUAN DIVERIFIKASI";
        case 2:
          return "DALAM PROSES";
        case 3:
          return "SELESAI DIPROSES";
        case 4:
          return "DITOLAK";
      }
    },
    showZoomImage(image) {
      this.bigImage = image;
      this.zoomImage = true;
    },
    hideZoomImage() {
      this.zoomImage = false;
    },
    openModal(status) {
      if (status == "approval") {
        this.isApproval = true;
      } else {
        this.isApproval = false;
      }
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
  },
  // mounted() {
  //   this.detailData();
  // },
  created() {
    this.details = this.$route.params.data;
    console.log(this.details);
    const user = JSON.parse(localStorage.getItem("user"));
    this.userId = user.id;
    this.userStatus = user.type;
  },
};
</script>

<style scoped>
.btn-success {
  background-color: #0d6769;
  /* color: white; */
}
hr {
  background-color: #0d6769;
  font-weight: bold;
}
.tab-list {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.005);

  /* border-radius: 10px; */
  /* margin: 50px; */
}
.detail-part {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 100px 150px 200px 50px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  box-sizing: border-box;
}
.text {
  color: #ffffff;
}
.text-green {
  color: #0d6769;
}
.text-content {
  font-size: 13px;

  color: black;
  text-align: justify;
  padding: 10px;
}
ul li {
  list-style-type: none;
  padding: 0;
}
.menu-laporan {
  cursor: pointer;
  background-color: white;
  border: 0.5px solid rgb(235, 235, 235);
  /* border-top-right-radius: 10px; */
  box-sizing: border-box;
}
.location {
  font-size: 8px;
  margin: 0;
  padding: 0;
}

.card-header {
  border: none;
}

/* Create two equal columns that sits next to each other */
.img-content {
  height: 250px;
  /* width: 500px; */
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.card {
  height: 360px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.active {
  border-top: 3px solid #0d6769;
}
.zoom-body {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.zoom-image {
  position: fixed;
  margin: auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-height: 500px;
  max-width: 1000px;
  /* border: 5px solid #0D6769; */
  box-shadow: 0 5px 15px black;
  /* box-shadow: 100px 150px 200px 50px rgba(63, 63, 68, 0.25), 0px 1px 3px rgba(63, 63, 68, 0.75); */
}
.x-close {
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  color: white;
  font-size: 40px;
  font-weight: 800;
}
.row-tindakan {
  width: 345px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .row-tindakan {
    width: 225px;
  }
  .table-striped {
    font-size: 11px;
  }
  .menu-laporan {
    font-size: 12px;
    background-color: white;
  }
  .zoom-body {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .zoom-image {
    position: fixed;
    margin: auto;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-height: 250px;
    max-width: 500px;
    /* border: 5px solid #0D6769; */
    box-shadow: 0 5px 15px black;
    /* box-shadow: 100px 150px 200px 50px rgba(63, 63, 68, 0.25), 0px 1px 3px rgba(63, 63, 68, 0.75); */
  }
  .x-close {
    position: fixed;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: white;
    font-size: 40px;
    font-weight: 800;
  }
  .card {
    height: 100%;
  }
  .map {
    height: 350px;
  }
}
</style>
